import FirstAdComp from 'components/homepage/Ads-Page/firstAdComp'
import SecondAdComp from 'components/homepage/Ads-Page/secondAdComp'
import React from 'react'
import OurClients from './ourClients'
import ThirdComp from './thirdComp'
import { NewData } from '../homepage/Ads-Page/config'
import AdContact from 'components/homepage/Ads-Page/AdContact'
import SeventhAdComp from 'components/homepage/websiteTranslation/seventhAdComp'
import About from './About'
import Industries from './Industries'
import RelativeLanguages from './RelativeLanguages'
import Services from './Services'
import { ServicesData } from 'components/LP/data'
import ReviewSection from './review'
import AdsCarousel from 'components/homepage/Ads-Page/carosule'
import { useState } from 'react'

const LP = () => {
  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [open, setOpen] = useState(false)

  if (typeof window !== 'undefined') {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }

  return (
    <>
      <FirstAdComp
        heading="שירותי תרגום מהירים לעסקים "
        content=" ליבת התפיסה העסקית שלנו היא לספק ללקוחותינו את חוויית התרגום הטובה ביותר. לספק ללקוחות שלנו את חוויית התרגום הטובה ביותר, זו הליבה של התפיסה העסקית שלנו. בטומדס עבודת התרגום שלכם תהיה בידיהם של מומחים מסורים שיבטיחו איכות גבוהה. אנו נתרגם לכם במהירות, נהיה זמינים עבורכם 24/7 ונציע לכם תעריפים תחרותיים שיתאימו לתקציב שלכם. "
        FromTextColor="צרו קשר"
        FromText1=" "
        FromText2=" "
        fontSize="text-[16px] sm:text-[18px]"
        marginTop="sm:mt-[37px]"
        HeadfontSize="sm:text-[45px]"
        paddingY="sm:pb-10 sm:pt-0 pt-[2.5rem]"
        bottomService
        contentTwo="ליבת התפיסה העסקית שלנו היא לספק ללקוחותינו את חוויית התרגום הטובה ביותר. לספק ללקוחות שלנו את חוויית התרגום הטובה ביותר, זו הליבה של התפיסה העסקית שלנו. בטומדס עבודת התרגום שלכם תהיה בידיהם של מומחים מסורים שיבטיחו איכות גבוהה. אנו נתרגם לכם במהירות, נהיה זמינים עבורכם 24/7 ונציע לכם תעריפים תחרותיים שיתאימו לתקציב שלכם."
        hideContent
        forHebrew
      />
      <OurClients />
      <ThirdComp
        forHebrew
        contentOne="אנו משקיעים את מירב מאמצינו ומבטיחים  שעבודת התרגום שלכם תקבל כל מה שנדרש. מנהלי הפרוייקטים שלנו עובדים יחד איתכם ומוודאים שאנשי המקצוע שלנו נותנים מענה מדויק בהתאם לכל דרישותיכם. צוות המתרגמים המסורים שלנו עובד בשקדנות וביעילות כדי לספק לכם את התוצאות הטובות ביותר. לאחר מכן, התוצרים עוברים בחינה של עורכים מומחים לצורך הבטחת איכות. אם זה לא מספיק, דעו שכל עבודה אצלנו מקבלת אחריות מקצועית למשך שנה."
        // firstHead=" "
        // fourthHead="?התרגום שלנו עובד"
        // thirdHead=" "
        // firstHead="איך תהליך  "
      />
      <SecondAdComp
        midContent
        data={NewData}
        parentDivwidth
        customWidth="max-w-[300px]"
        newCarousel
        headTitle="למה לעבוד איתנו?"
        fontBold
        title="למה לעבוד איתנו?"
        gapX
        noPadding
      />
      <AdContact
        open={open}
        setOpen={setOpen}
        head="מוכנים להתחיל?"
        paddingY="pt-14 sm:pb-10"
        mobileContactButton
        forHebrew
        btnName="צרו קשר"
      />
      <SeventhAdComp
        serviceSection
        content="טומדס שומרת על האיכות הגבוהה ביותר בזכות הסמכה לתקני ISO, תחת פיקוח של ארגון התקינה הבינלאומי. החברה מוסמכת לסטנדרטים הבאים : תקן מערכות ניהול איכות, תקן לשירותי תרגום, תקן לשירותי תרגום מכונה עם עריכה אנושית (Machine Translation Post-editing)."
        head="עמידה בתקני איכות בינלאומיים ISO"
        forHebrew
      />
      <RelativeLanguages forHebrew open={open} setOpen={setOpen} />
      <Industries
        open={open}
        setOpen={setOpen}
        head="שירותי תרגום לכל תעשייה"
        content="לא כל מתרגם יכול לקבל את העבודה שלכם מטומדס. אנו דואגים שרק מומחים מנוסים בעלי הרקע המתאים יקבלו את עבודת התרגום שתמסרו. המתרגמים שלנו חולשים על מגוון רחב של התמחויות."
      />
      <ReviewSection
        open={open}
        setOpen={setOpen}
        heading="סל פתרונות התרגום המגוון שלנו"
        forHebrew
      />
      <SecondAdComp BottomSection NotshowCarousel forHebrew />
      <About forHebrew />
    </>
  )
}

export default LP
