import Layout from 'components/layout'
import LP from 'components/LP'
import React from 'react'
import SEO from 'components/seo'

const index = () => {
  return (
    <Layout>
      <SEO
        title="חברת תרגום - שירותי תרגום לעסקים"
        description="טומדס הינה חברת תרגום מובילה ומבוססת טכנולוגיה עם למעלה מ-95 אלף לקוחות בישראל ובעולם."
      />
      <LP />
    </Layout>
  )
}

export default index
